export enum TypeRange {
    NUMBER = 'number',
    SELECT = 'select'
}

export enum TypesFilter {
    CREDIT = 'credito',
    PROTECTION = 'blindaje',
    BRAND = 'brand',
    AMOUNT = 'amount',
    BUDGET = 'budget',
    MINBUDGET = 'initial_price',
    MAXBUDGET = 'final_price',
    YEAR = 'year',
    MINYEAR = 'initial_year',
    MAXYEAR = 'final_year',
    MILEAGE = 'mileage',
    MINMILEAGE = 'initial_mileage',
    MAXMILEAGE = 'final_mileage',
    TYPE = 'type',
    TRANSMISSION = 'transmission',
    OWNERS = 'owner',
    STATE = 'estado',
    MODEL = 'model',
    ORDER = 'order',
    CRITERIA = 'criteria',
}

export interface IStoreFilters {
    text: string;
    value: string;
    filterId: string;
    type: TypesFilter;
    idValue?: any;
    display?: boolean;
    append?: boolean;
}