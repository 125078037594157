import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterState } from './filters.reducers';

export const selectFilterState = createFeatureSelector<FilterState>('filters2');

// Selector para todos los filtros actuales
export const selectCurrentFilters = createSelector(
  selectFilterState,
  (state: FilterState) => state.filters
);

export const selectFilteredData = createSelector(
  selectFilterState,
  (state: FilterState) => state.filteredData
);

export const selectCurrentPage = createSelector(
    selectFilterState,
    (state: FilterState) => state.page
);

export const selectTotalRegisters = createSelector(
    selectFilterState,
    (state: FilterState) => state.total
);

export const selectListFilters = createSelector(
    selectFilterState,
    (state: FilterState) => state.filteredItems
  );